import { BoltIcon, BoltSlashIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { FC, useState } from "react";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import useBikes from "../api/hooks/useBikes";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

interface IBarcodeScannerProps {
  isOpen: boolean;
  onClose: () => void;
}

const BarcodeScanner: FC<IBarcodeScannerProps> = ({ isOpen, onClose }) => {
  const { bikes } = useBikes();
  const navigate = useNavigate();
  const [isTorchOn, setIsTorchOn] = useState(false);

  const handleScan = (data: string) => {
    if (bikes?.find((bike) => bike.frameNumber === data)) {
      onClose();
      toast.success(`Bike ${data} found`);
      navigate(`/dashboard/bikes/${data}`);
    } else {
      toast.error(`Bike ${data} not found`);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
      <div className="relative w-full max-w-md border border-4 border-customBlue m-2 rounded-lg shadow-lg">
        <button
          className="absolute z-20 top-4 right-4 bg-customBlue p-2 rounded-lg"
          onClick={onClose}
        >
          <XMarkIcon className="w-7 h-7 text-white" />
        </button>
        <button
          className="absolute z-20 top-4 right-16 bg-customBlue p-2 rounded-lg"
          onClick={() => setIsTorchOn(!isTorchOn)}
        >
          {isTorchOn ? (
            <BoltIcon className="w-7 h-7 text-white" />
          ) : (
            <BoltSlashIcon className="w-7 h-7 text-white" />
          )}
        </button>
        <div className="relative">
          <BarcodeScannerComponent
            facingMode="environment"
            torch={isTorchOn}
            onUpdate={(err, result) => {
              if (result) handleScan(result.getText());
            }}
          />
          <div className="absolute inset-0 flex items-center justify-center pointer-events-none">
            <div className="border-4 border-dashed rounded-lg border-customDarkBlue w-3/4 h-1/4"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BarcodeScanner;
