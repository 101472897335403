import { UserIcon } from "@heroicons/react/24/solid";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import Button from "../../modules/components/Button";

export default function ProfilePage() {
  const { getUser, getPermissions, isLoading, getPermission } = useKindeAuth();

  const user = getUser?.();
  const permissions = getPermissions?.()?.permissions;

  const userName = user ? `${user.given_name} ${user.family_name}` : null;
  const userEmail = user?.email;
  const userId = user?.id;

  return (
    <div className="py-12 px-4 max-w-7xl mx-auto">
      <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between mb-6">
        <div>
          <h1 className="text-3xl font-bold">Profile</h1>
        </div>
      </div>

      <div className="mt-4 grid grid-cols-1 sm:grid-cols-2">
        {isLoading || !user ? (
          <div>
            <div className="items-center space-y-3">
              <div className="w-32 h-32 rounded-full mb-4 overflow-hidden bg-gray-100 animate-pulse" />
              <p className="bg-gray-100 h-4 rounded animate-pulse w-56"></p>
              <p className="bg-gray-100 h-4 rounded animate-pulse w-40"></p>
            </div>
            <p className="bg-gray-100 h-4 mt-6 rounded animate-pulse w-52"></p>
            <div className="mt-8">
              <h2 className="text font-semibold text-gray-800">Permissions</h2>
              <ul className="space-y-4 mt-1">
                {Array.from({ length: 4 }).map((_, index) => (
                  <li
                    key={index}
                    className="bg-gray-100 h-4 rounded animate-pulse w-32"
                  />
                ))}
              </ul>
            </div>
          </div>
        ) : (
          <div>
            <div className="items-center">
              <div className="w-28 h-28 rounded-full border-4 border-gray-300 mb-4 overflow-hidden flex items-center justify-center">
                <UserIcon className="w-24 h-24 mt-10 text-gray-300" />
              </div>

              <h2 className="text-xl font-semibold text-gray-800">
                {userName || "Unknown"}
              </h2>
              <p className="text-gray-500">{userEmail}</p>
              <p className="text-xs mt-4">{userId}</p>
            </div>

            <div className="mt-8">
              <h2 className="text font-semibold text-gray-800">Permissions</h2>
              <ul className="list-disc list-inside ml-5 mt-1 space-y-1">
                {permissions && permissions.length > 0 ? (
                  permissions.map((permission: any, index: number) => (
                    <li key={index} className="text-gray-800">
                      {permission}
                    </li>
                  ))
                ) : (
                  <div>No permissions available</div>
                )}
              </ul>
            </div>
          </div>
        )}
        <div className="flex justify-end items-start space-x-2">
          {getPermission && getPermission("PURGE_LOGS")?.isGranted ? (
            <Button title="Purge old logs" variant="primary" />
          ) : null}
          {/*{getPermission && getPermission("VIEW_LOGS")?.isGranted ? (
            <Button title="TBA" variant="primary" className="mt-4" />
          ) : null}*/}
        </div>
      </div>
    </div>
  );
}
