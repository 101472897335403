import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "../../../constants";
import { IReport } from "../interfaces/report";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";

interface IUseDeleteReportProps {
  onSuccess?: () => void;
  onError?: () => void;
}

export const useDeleteReport = (props?: IUseDeleteReportProps) => {
  const { getToken } = useKindeAuth();
  const queryClient = useQueryClient();

  const deleteReportWithJournalNumber = async (journalNumber: string) => {
    const accessToken = await getToken?.();
    return await axios.delete(API_URL + `/reports/${journalNumber}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  };

  return useMutation({
    mutationKey: ["delete", "report"],
    mutationFn: (report: IReport) =>
      deleteReportWithJournalNumber(report.journalNumber),
    onError: (error: Error) => {
      console.error(error);
      props?.onError?.();
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["reports"] });
      props?.onSuccess?.();
    },
  });
};
