import TableActions from "./TableActions";
import { TableProps } from "./TableProps";

function Table<T>(props: TableProps<T>) {
  return (
    <div className="relative overflow-x-auto rounded-t-lg mt-2">
      <table className="w-full text-gray-500">
        <thead className="text-xs text-left text-gray-700 uppercase">
          <tr>
            {props.columns.map((column, index) => (
              <th key={index} className={column.classNameHeader || ""}>
                {column.label}
              </th>
            ))}
            {props.tableActions && <th></th>}
          </tr>
        </thead>
        <tbody>
          {props.isLoading &&
            new Array(5).fill(0).map((_, i) => (
              <tr key={i}>
                <td colSpan={6} className="py-3">
                  <div className="h-4 bg-gray-200 rounded animate-pulse"></div>
                </td>
              </tr>
            ))}

          {props.data.length === 0 &&
            !props.isLoading &&
            new Array(1).fill(0).map((_, i) => (
              <tr key={i}>
                <td colSpan={6} className="py-4 items-center text-center">
                  <div className="h-4">{props.emptyMessage}</div>
                </td>
              </tr>
            ))}

          {props.data &&
            props.data.map((item, index) => (
              <tr
                key={index}
                className="text-left text-balance text-xs sm:text-sm"
              >
                {props.columns.map((column, colIndex) => (
                  <td
                    key={colIndex}
                    className={`py-7 sm:py-5 ${column.classNameCell || ""}`}
                  >
                    {column.render
                      ? // Use custom render function if defined
                        column.render(column.accessor(item), item)
                      : // Default behavior for string or other types
                      typeof column.accessor(item) === "string"
                      ? column.accessor(item)
                      : column.accessor(item).toString()}
                  </td>
                ))}

                {props.tableActions && (
                  <td className="mr-2 w-2 sm:pr-0 md:w-32 text-right">
                    <TableActions
                      item={item}
                      deleteMessageFn={props.tableActions.deleteMessageFn}
                      deleteFn={props.tableActions.deleteFn}
                      idAccessor={props.idAccessor}
                      editPermission={props.editPermission}
                      deletePermission={props.deletePermission}
                      hideViewButton={props.hideViewButton}
                    />
                  </td>
                )}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}

export default Table;
