import { Link } from "react-router-dom";
import { format } from "date-fns";
import { da } from "date-fns/locale";
import useReports from "../../modules/api/hooks/useReports";
import { useDeleteReport } from "../../modules/api/hooks/useDeleteReport";
import { IReport } from "../../modules/api/interfaces/report";
import Table from "../../modules/components/table/Table";
import Button from "../../modules/components/Button";
import { useState } from "react";
import SearchComponent from "../../modules/components/SearchComponent";

export function ReportsPage() {
  const { reports, isLoading } = useReports();
  const [searchData, setSearchData] = useState<any>(reports);
  const { mutate: deleteReport } = useDeleteReport({
    onSuccess: () => {},
    onError: () => {
      window.alert("Could not delete report. Please try again.");
    },
  });

  const handleDelete = (report: IReport) => {
    deleteReport(report);
  };

  const columns = [
    {
      label: "Journal number",
      accessor: (row: IReport) => row.journalNumber,
    },
    {
      label: "Date",
      accessor: (row: IReport) => row.date,
      render: (date: Date) => {
        return format(new Date(date), "dd-MM-yyyy", {
          locale: da,
        });
      },
    },
    {
      label: "Description",
      accessor: (row: IReport) => row.description,
      render: (description: string) => {
        return description.slice(0, 80) + "...";
      },
      classNameHeader: "hidden xl:table-cell",
      classNameCell: "hidden xl:table-cell",
    },
    {
      label: "Frame number",
      accessor: (row: IReport) => "SF" + row.bikeReference,
    },
    {
      label: "Active?",
      accessor: (row: IReport) => row.active === true,
      render: (active: boolean) => (
        <span
          className={`px-2 py-1 rounded-full text-white ${
            active ? "bg-green-500" : "bg-red-500"
          }`}
        >
          {active ? "Yes" : "No"}
        </span>
      ),
      classNameCell: "text-center sm:text-left",
      classNameHeader: "text-center sm:text-left",
    },
  ];

  return (
    <div className="py-12 px-4 max-w-7xl mx-auto">
      <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between">
        <div>
          <h1>Reports</h1>
        </div>
        <div className="mt-4 sm:mt-0 w-full sm:w-auto sm:flex-shrink-0">
          <Link to="./new">
            <Button
              title="Create report"
              variant="primary"
              className="w-full sm:w-auto"
            />
          </Link>
        </div>
      </div>
      <div className="flex flex-row mt-4 gap-4">
        <SearchComponent
          placeholder="Search by journal number..."
          data={reports || []}
          inputMode="search"
          filterAttribute="journalNumber"
          onChange={(data) => {
            setSearchData(data);
          }}
        />
      </div>
      <Table
        data={searchData || []}
        columns={columns}
        isLoading={isLoading}
        tableActions={{
          deleteFn: (item) => handleDelete(item),
          deleteMessageFn: (item) =>
            `Are you sure you want to delete ${item.journalNumber}?`,
        }}
        idAccessor={(row) => row.journalNumber}
        emptyMessage="No reports found"
        editPermission="EDIT_REPORT"
        deletePermission="DELETE_REPORT"
      />
    </div>
  );
}
