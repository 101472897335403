import { Navigate, useNavigate, useParams } from "react-router-dom";
import { format } from "date-fns";
import { da } from "date-fns/locale";
import useBikes from "../../modules/api/hooks/useBikes";
import { BikeType, hexToBikeColorMap } from "../../modules/api/interfaces/bike";
import { IReport } from "../../modules/api/interfaces/report";
import Table from "../../modules/components/table/Table";
import InputField from "../../modules/components/InputField";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";

export function ShowBikePage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { bikes } = useBikes();
  const bike = bikes?.find((bike) => bike.frameNumber === id);

  if (!bike) {
    return <Navigate to="/dashboard/bikes" />;
  }

  const columns = [
    {
      label: "Journal number",
      accessor: (row: IReport) => row.journalNumber,
      render: (journalNumber: string) => {
        return (
          <button
            className="underline hover:text-customBlue cursor-pointer"
            onClick={() => {
              navigate("/dashboard/reports/" + journalNumber);
            }}
          >
            {journalNumber}
          </button>
        );
      },
    },
    {
      label: "Date",
      accessor: (row: IReport) => row.date,
      render: (date: Date) => {
        return format(new Date(date), "dd-MM-yyyy", {
          locale: da,
        });
      },
    },
    {
      label: "Description",
      accessor: (row: IReport) => row.description,
      render: (description: string) => {
        return description.slice(0, 80) + "...";
      },
      classNameHeader: "hidden sm:table-cell",
      classNameCell: "hidden sm:table-cell",
    },
    {
      label: "Frame number",
      accessor: (row: IReport) => "SF" + row.bikeReference,
      classNameHeader: "hidden sm:table-cell",
      classNameCell: "hidden sm:table-cell",
    },
    {
      label: "Active?",
      accessor: (row: IReport) => row.active === true,
      render: (active: boolean) => (
        <span
          className={`px-2 py-1 rounded-full text-white ${
            active ? "bg-green-500" : "bg-red-500"
          }`}
        >
          {active ? "Yes" : "No"}
        </span>
      ),
    },
  ];

  return (
    <div className="py-12 px-4 max-w-7xl mx-auto">
      <div className="flex items-center justify-between sm:justify-start mb-6 space-x-1">
        <button
          className="btn-icon"
          onClick={() => navigate("/dashboard/bikes/")}
        >
          <ArrowLeftIcon className=" h-6 w-6 font-bold text-gray-400" />
        </button>
        <p className="text-sm tracking-wide font-medium sm:text-2xl">
          Bike - {bike?.frameNumber}
        </p>
        <div sr-only></div>
      </div>

      <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
        <InputField
          label="Frame number"
          name="frame-number"
          type="text"
          value={"SF" + bike?.frameNumber?.toString() || ""}
          onChange={() => {}}
          readonly
        />
        <InputField
          label="Type"
          name="bike-type"
          type="text"
          value={
            bike?.bikeType !== undefined ? BikeType[bike.bikeType] : "Default"
          }
          onChange={() => {}}
          readonly
        />
        <div className="relative w-full">
          <p className="block mb-2 text-sm font-medium text-gray-900">Color</p>
          <div className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 text-left relative focus:ring-blue-600 focus:border-blue-600">
            <span
              className="w-4 h-4 rounded-full mr-2 absolute top-1/2 transform -translate-y-1/2 left-3"
              style={{ backgroundColor: bike?.color }}
            ></span>
            <span className="ml-8">{hexToBikeColorMap[bike.color]}</span>
          </div>
        </div>
      </div>
      <h2 className="block mt-8 mb-2 text-gray-900">Bikes report history</h2>
      <Table
        data={bike?.reports || []}
        columns={columns}
        tableActions={{
          deleteFn: (item) => console.log(item),
          deleteMessageFn: (item) =>
            `Are you sure you want to delete ${item.journalNumber}?`,
        }}
        idAccessor={(row) => row.journalNumber}
        emptyMessage="No reports found for this bike"
        hideViewButton={true}
      />
    </div>
  );
}
