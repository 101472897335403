export const IS_DEV = false;
export const API_URL = IS_DEV
  ? "https://localhost:7021/api"
  : "https://api.swapclaim.kyed3.dk/api";

export const CLIENT_ID = IS_DEV
  ? "79c4ea7491e84c2783d2ac9863dbde2a"
  : "11c63c2eb5174ba3a00ad305d8c9bf51";

export const KINDE_DOMAIN = IS_DEV
  ? "https://swapclaim-development.eu.kinde.com"
  : "https://swapclaim.kinde.com";
