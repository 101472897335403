import Chart from "react-google-charts";
import useStatistics from "../../modules/api/hooks/useStatistics";
import StatCard from "../../modules/components/StatCard";
import { useEffect, useState } from "react";
import { format } from "date-fns";

export const optionsChartOne = {
  vAxis: { minValue: 0 },
  legend: { position: "none" },
  chartArea: { width: "80%", height: "80%" },
  isStacked: false,
  colors: ["#1F9EDA", "#197EAE"],
  areaOpacity: 0.4,
};

export const optionsChartTwo = {
  vAxis: { minValue: 0 },
  legend: { position: "none" },
  chartArea: { width: "80%", height: "80%" },
  isStacked: false,
  colors: ["#1F9EDA", "#197EAE"],
  areaOpacity: 0.4,
};

export const optionsThree = {
  pieHole: 0.4,
  is3D: false,
  colors: ["#1F9EDA", "#197EAE", "#00BFFF", "#0F4C75"],
  legend: {
    position: "bottom",
    textStyle: { fontSize: 10 },
  },
  chartArea: {
    width: "80%",
    height: "80%",
  },
};

type ChartData = (string | number)[][];

export function MainPage() {
  const { dashboardStats, isLoading } = useStatistics();
  const [dataChartOne, setDataChartOne] = useState<ChartData>([
    ["Month", "Bikes", "Reports"],
  ]);
  const [dataChartTwo, setDataChartTwo] = useState<ChartData>([
    ["Month", "Active Reports", "Archived Reports"],
  ]);
  const [dataChartThree, setDataChartThree] = useState<ChartData>([
    ["Type", "Color"],
  ]);

  useEffect(() => {
    const chartDataOne: ChartData = [["Month", "Bikes", "Reports"]];
    const chartDataTwo: ChartData = [
      ["Month", "Active Reports", "Archived Reports"],
    ];
    const chartDataThree: ChartData = [["Type", "Color"]];

    if (dashboardStats && Array.isArray(dashboardStats.pastYearStats)) {
      for (let i = 0; i < dashboardStats.pastYearStats.length; i++) {
        const item = dashboardStats.pastYearStats[i];
        if (item) {
          const month = format(new Date(item.month), "MMM").toString();
          chartDataOne.push([
            month,
            Number(item.totalBikes),
            Number(item.totalReports),
          ]);
          chartDataTwo.push([
            month,
            Number(item.activeReports),
            Number(item.archivedReports),
          ]);
        }
      }
    }

    if (dashboardStats && Array.isArray(dashboardStats.bikeTypes)) {
      for (let i = 0; i < dashboardStats.bikeTypes.length; i++) {
        const item = dashboardStats.bikeTypes[i];
        if (item) {
          const title = item.title;
          chartDataThree.push([title, Number(item.value)]);
        }
      }
    }

    if (chartDataOne.length === 1) {
      chartDataOne.push(["", 0, 0]);
    }
    if (chartDataTwo.length === 1) {
      chartDataTwo.push(["", 0, 0]);
    }
    if (chartDataThree.length === 1) {
      chartDataThree.push(["", 0]);
    }

    setDataChartOne(chartDataOne);
    setDataChartTwo(chartDataTwo);
    setDataChartThree(chartDataThree);
  }, [dashboardStats]);
  return (
    <div className="py-12 px-4 max-w-7xl mx-auto">
      <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between">
        <div>
          <h1 className="text-4xl">SwapClaim Dashboard</h1>
          <p className="text-gray-400 text-sm">
            See all statistics, FAQ and recent data here on this page.
          </p>
        </div>
      </div>
      <div className="py-8 mx-auto text-center">
        <dl className="grid gap-8 mx-auto text-gray-900 sm:grid-cols-4">
          {isLoading
            ? Array.from({ length: 4 }).map((_, index) => (
                <div
                  key={index}
                  className="h-36 bg-gray-200 rounded animate-pulse"
                ></div>
              ))
            : dashboardStats?.statistics.map((stat, index) => (
                <StatCard key={index} statistics={stat} />
              ))}
        </dl>
      </div>

      <div className="mx-auto text-center grid gap-8 grid-cols-1 sm:grid-cols-2 text-gray-900">
        {isLoading ? (
          Array.from({ length: 3 }).map((_, index) => (
            <div
              key={index}
              className={`h-80 bg-gray-200 rounded animate-pulse ${
                index === 2 ? "sm:col-span-2" : ""
              }`}
            ></div>
          ))
        ) : (
          <>
            <div className="block p-2 bg-white border border-gray-200 rounded-lg shadow">
              <h4 className="text-lg">Bikes & Reports</h4>
              <Chart
                chartType="AreaChart"
                width="100%"
                height="274px"
                data={dataChartOne}
                options={optionsChartOne}
                className="mx-auto"
              />
            </div>

            <div className="block p-2 bg-white border border-gray-200 rounded-lg shadow">
              <h4 className="text-lg">Active & Archived Reports</h4>
              <Chart
                chartType="AreaChart"
                width="100%"
                height="274px"
                data={dataChartTwo}
                options={optionsChartTwo}
                className="mx-auto"
              />
            </div>

            <div className="block p-2 bg-white border border-gray-200 rounded-lg shadow sm:col-span-2">
              <h4 className="text-lg">Bike Types</h4>
              <Chart
                chartType="PieChart"
                width="100%"
                height="274px"
                data={dataChartThree}
                options={optionsThree}
                className="mx-auto"
              />
            </div>
          </>
        )}
      </div>

      <hr className="h-px my-8 bg-gray-200 border-2" />

      <h2 className="block mt-8 mb-2 text-gray-900">Recent reports created</h2>
      <p className="text-secondary">Comming soon...</p>
      <hr className="h-px my-8 bg-gray-200 border-2" />

      <h2 className="block mt-8 mb-2 text-gray-900">Recent bikes created</h2>
      <p className="text-secondary">Comming soon...</p>
      <hr className="h-px my-8 bg-gray-200 border-2" />

      <h1 className="text-2xl font-medium mb-1 text-black">FAQ</h1>
      <h2 className="block mt-6 mb-2 text-gray-900">How to create a Bike?</h2>
      <ol className="list-decimal ml-5 mt-2 text-secondary">
        <li>
          Navigate to the <strong>Bikes</strong> section from the dashboard.
        </li>
        <li>
          Click the <strong>Create bike</strong> button at the top.
        </li>
        <li>
          Fill in the bike details:
          <ul className="list-disc ml-5 mt-1">
            <li>
              <strong>Frame Number</strong>: Enter the unique frame number for
              the bike.
            </li>
            <li>
              <strong>Type</strong>: Select the appropriate bike type from the
              dropdown.
            </li>
            <li>
              <strong>Color</strong>: Select the appropriate color of the bike
              from the dropdown.
            </li>
          </ul>
        </li>
        <li>
          Click <strong>Create bike</strong> button at the bottom to create the
          bike.
        </li>
      </ol>
      <h2 className="block mt-6 mb-2 text-gray-900">How to create a Report?</h2>
      <ol className="list-decimal ml-5 mt-2 text-secondary">
        <li>
          Navigate to the <strong>Reports</strong> section from the dashboard.
        </li>
        <li>
          Click the <strong>Create report</strong> button at the top-right
          corner.
        </li>
        <li>
          Fill in the report details:
          <ul className="list-disc ml-5 mt-1">
            <li>
              <strong>Journal Number</strong>: Enter the police journal number.
            </li>
            <li>
              <strong>Date</strong>: Select the report date.
            </li>
            <li>
              <strong>Description</strong>: Provide a brief description of the
              report.
            </li>
            <li>
              <strong>Active</strong>: Select if the report is currently active
              (Yes/No).
            </li>
            <li>
              <strong>Bike Frame Number</strong>: Enter the frame number of the
              bike linked to this report.
            </li>
          </ul>
        </li>
        <li>
          Click <strong>Create report</strong> button at the botom to create the
          report.
        </li>
      </ol>
      <hr className="h-px my-8 bg-gray-200 border-2" />
      <div className="text-gray-400">
        Last system update: <p className="font-bold">14/10/2024</p>
      </div>
    </div>
  );
}
