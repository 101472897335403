import { Navigate, useNavigate, useParams } from "react-router-dom";
import InputField from "../../modules/components/InputField";
import useLogs from "../../modules/api/hooks/useLogs";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";

export function ShowLogsPage() {
  const { id } = useParams();
  const { logs } = useLogs();
  const navigate = useNavigate();
  const log = logs?.find((log) => log.requestId === id);

  if (!log) {
    return <Navigate to="/dashboard/logs" />;
  }

  const { requestId, ...logWithoutRequestId } = log;

  return (
    <div className="py-12 px-4 max-w-7xl mx-auto">
      <div className="flex items-center justify-between sm:justify-start mb-6 space-x-1">
        <button
          className="btn-icon"
          onClick={() => navigate("/dashboard/logs/")}
        >
          <ArrowLeftIcon className=" h-6 w-6 font-bold text-gray-400" />
        </button>
        <p className="text-sm tracking-wide font-medium sm:text-2xl">
          Log - {log?.requestId}
        </p>
        <div sr-only></div>
      </div>

      <div className="grid gap-4 sm:gap-6 w-full">
        <InputField
          label="Request ID"
          name="request-id"
          type="text"
          value={log?.requestId}
          onChange={() => {}}
          readonly
          className="break-all"
        />
        <pre className="text-sm break-all overflow-auto max-w-full">
          {JSON.stringify(logWithoutRequestId, null, 2)}
        </pre>
      </div>
    </div>
  );
}
