import React, { useEffect, useState } from "react";
import { bikeColorOptions, BikeType } from "../api/interfaces/bike";

interface DropdownProps {
  bikeType: BikeType;
  onSelectColor: (color: string) => void;
}

const BikeColorDropdown: React.FC<DropdownProps> = ({
  bikeType,
  onSelectColor,
}) => {
  const [selectedColorLabel, setSelectedColorLabel] = useState<string | null>(
    null
  );
  const [selectedColorClass, setSelectedColorClass] = useState<string | null>(
    null
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    setSelectedColorLabel(null);
    setSelectedColorClass(null);
    setIsOpen(false);
    onSelectColor("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bikeType]);

  const handleColorSelect = (colorValue: string, colorClass: string) => {
    setSelectedColorLabel(colorValue);
    setSelectedColorClass(colorClass);
    setIsOpen(false);
    onSelectColor(colorClass);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative w-full">
      {/* Dropdown button */}
      <p className="block mb-2 text-sm font-medium text-gray-900">Color</p>
      <button
        onClick={toggleDropdown}
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-customBlue focus:border-customBlue block w-full p-2.5 text-left"
      >
        {selectedColorClass && (
          <span
            className="w-4 h-4 rounded-full mr-2 float-left mt-0.5"
            style={{ backgroundColor: selectedColorClass }}
          ></span>
        )}
        {selectedColorLabel ? selectedColorLabel : "Select a color"}
        <span className="float-right">{isOpen ? "▲" : "▼"}</span>
      </button>

      {/* Collapsible Dropdown Menu */}
      {isOpen && (
        <ul className="absolute z-10 w-full bg-white border border-gray-300 rounded-lg mt-1 shadow-lg">
          {bikeColorOptions[bikeType].map((colorOption, index) => (
            <li
              key={index}
              className="flex items-center px-4 py-2 cursor-pointer hover:rounded-lg hover:bg-gray-100 text-sm"
              onClick={() =>
                handleColorSelect(colorOption.value, colorOption.colorClass)
              }
            >
              <span
                className="w-4 h-4 rounded-full mr-2"
                style={{ backgroundColor: colorOption.colorClass }}
              ></span>
              {colorOption.value}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default BikeColorDropdown;
