import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "../../../constants";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { ILoggingModule } from "../interfaces/loggingModule";

// React Query hook to fetch logs with access token
export const useLogs = () => {
  const { getToken } = useKindeAuth();

  const fetchLogs = async (): Promise<ILoggingModule[]> => {
    try {
      const accessToken = await getToken?.();
      const { data } = await axios.get<ILoggingModule[]>(API_URL + "/logs", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return data;
    } catch (error: any) {
      console.error("Failed to fetch logs:", error);
      throw new Error(error.message);
    }
  };

  const { isLoading, data, error } = useQuery({
    queryKey: ["logs"],
    queryFn: fetchLogs,
    select: (data) => data || [],
  });

  return { isLoading, logs: data, error };
};

export default useLogs;
