import { useState } from "react";
import { useCreateBike } from "../../modules/api/hooks/useCreateBike";
import { BikeType } from "../../modules/api/interfaces/bike";
import BikeColorDropdown from "../../modules/components/BikeColorPicker";
import Button from "../../modules/components/Button";
import toast from "react-hot-toast";

export interface IBikeForm {
  frameNumber: string;
  bikeType: BikeType;
  color: string;
}

export function NewBikePage() {
  const createBikeMutation = useCreateBike({
    onError: (errorMessage) => {
      setError(errorMessage);
      setIsCreating(false);
    },
  });
  const [form, setForm] = useState<IBikeForm>({
    frameNumber: "",
    bikeType: -1,
    color: "",
  });
  const [isCreating, setIsCreating] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = () => {
    let errorMessage = "";

    if (!form.frameNumber) {
      errorMessage += "• Frame number is required.\n";
    }
    if (form.bikeType === -1) {
      errorMessage += "• Type is required.\n";
    }
    if (!form.color) {
      errorMessage += "• Color is required.\n";
    }

    if (errorMessage) {
      setError(errorMessage.trim());
      return;
    }

    setIsCreating(true);
    toast.promise(createBikeMutation.mutateAsync(form), {
      loading: "Creating bike...",
      success: "Bike created successfully!",
      error: "Error creating bike.",
    });
  };

  return (
    <>
      <div className="py-12 px-4 max-w-7xl mx-auto">
        <div className="flex items-center justify-between mb-6">
          <div>
            <h1>Create New Bike</h1>
            <p className="text-gray-400 text-sm">
              Create a new bike here on this page.
            </p>
          </div>
        </div>
        <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
          <div>
            <label
              htmlFor="frame-number"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Frame number
            </label>
            <input
              type="text"
              name="name"
              id="frame-number"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
              placeholder="Type the frame number of the bike"
              required
              autoComplete="off"
              value={form.frameNumber}
              onChange={(e) =>
                setForm({ ...form, frameNumber: e.target.value })
              }
            />
          </div>
          <div>
            <label
              htmlFor="bike-type"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Type
            </label>
            <select
              id="bike-type"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
              required
              autoComplete="off"
              value={form.bikeType}
              onChange={(e) =>
                setForm({ ...form, bikeType: Number(e.target.value) })
              }
            >
              <option value={BikeType.Default} disabled>
                Select the type
              </option>
              <option value={BikeType.Original}>Original</option>
              <option value={BikeType.Deluxe7}>Deluxe7</option>
              <option value={BikeType.Power1}>Power1</option>
              <option value={BikeType.Power7}>Power7</option>
            </select>
          </div>
          <div>
            <BikeColorDropdown
              bikeType={form.bikeType}
              onSelectColor={(color) => setForm({ ...form, color })}
            />
          </div>
        </div>
        <Button
          title="Create bike"
          variant="primary"
          onClick={handleSubmit}
          isLoading={isCreating}
          disabled={isCreating}
          className="mt-4"
        />
        {error && (
          <div className="text-red-500 mt-4 whitespace-pre-wrap">{error}</div>
        )}
      </div>
    </>
  );
}
