import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { API_URL } from "../../../constants";
import { IDashboardStatistics } from "../interfaces/dashboard_statistics";

// React Query hook to fetch statistics with access token
export const useStatistics = () => {
  const { getToken } = useKindeAuth();

  const fetchStatistics = async (): Promise<IDashboardStatistics> => {
    try {
      const accessToken = await getToken?.();
      const { data } = await axios.get<IDashboardStatistics>(
        API_URL + "/statistics",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      console.error("Failed to fetch statistics:", error);
      throw new Error(error.message);
    }
  };

  const { isLoading, data, error } = useQuery({
    queryKey: ["statistics"],
    queryFn: fetchStatistics,
    select: (data) => data || [],
  });

  return { isLoading, dashboardStats: data, error };
};

export default useStatistics;
