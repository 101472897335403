import React, { useCallback, useEffect, useState } from "react";
import { ISearch } from "../api/interfaces/search";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../constants";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import Button from "./Button";
import { XMarkIcon } from "@heroicons/react/24/solid";

export function SearchModal({
  isOpen,
  onClose,
  onOpen,
}: {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
}) {
  const { getToken } = useKindeAuth();
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedTerm, setDebouncedTerm] = useState(searchTerm);

  const navigate = useNavigate();
  const { data, isLoading, error } = useQuery<ISearch[], Error>({
    queryKey: ["searchResults", debouncedTerm], // Corrected form with queryKey
    queryFn: () => fetchSearchResults(debouncedTerm), // Pass query function here
    enabled: !!debouncedTerm, // Only fetch when debounced term is present
  });

  // Function to fetch search results from API
  const fetchSearchResults = async (query: string) => {
    const accessToken = await getToken?.();
    const { data } = await axios.get(API_URL + `/search/${query}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return data;
  };

  const keyPressed = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Escape") onClose();
      if ((event.metaKey || event.ctrlKey) && event.code === "Space") onOpen();
    },
    [onClose, onOpen]
  );

  useEffect(() => {
    document.addEventListener("keydown", keyPressed, false);
    return () => {
      document.removeEventListener("keydown", keyPressed, false);
    };
  }, [keyPressed]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedTerm(searchTerm);
    }, 1200); // 1.2s debounce delay

    return () => {
      clearTimeout(handler); // Clean up timeout if searchTerm changes
    };
  }, [searchTerm]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75 z-50">
      <div className="relative bg-white w-3/4 md:w-1/2 rounded-lg shadow-lg overflow-hidden">
        <div className="flex items-center justify-between px-6 py-4 border-b border-gray-200">
          <input
            id="search-field"
            type="text"
            className="w-full bg-gray-100 px-4 py-2 text-gray-800 rounded-lg focus:outline-none focus:ring focus:ring-blue-500"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleInputChange}
          />
          <button className="ml-1 btn-secondary" onClick={onClose}>
            <XMarkIcon className="w-5 h-5" />
          </button>
        </div>

        <div className="p-6 max-h-[50vh] overflow-y-auto">
          {isLoading && <p className="text-gray-500 text-center">Loading...</p>}
          {error && (
            <p className="text-red-500 text-center">Error fetching results</p>
          )}
          {data && data.length > 0 ? (
            <ul>
              {data.map((result, index) => (
                <li key={index} className="space-y-4">
                  {result.frameNumber ? (
                    <p
                      className="bg-gray-100 m-1 p-2 rounded-lg hover:bg-gray-200 hover:underline"
                      onClick={() => {
                        navigate("/dashboard/bikes/" + result.frameNumber);
                        onClose();
                      }}
                    >
                      Frame Number: {result.frameNumber}
                    </p>
                  ) : null}
                  {result.journalNumber ? (
                    <p
                      className="bg-gray-100 m-1 p-2 rounded-lg hover:bg-gray-200 hover:underline"
                      onClick={() => {
                        navigate("/dashboard/reports/" + result.journalNumber);
                        onClose();
                      }}
                    >
                      Journal Number: {result.journalNumber}
                    </p>
                  ) : null}
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-gray-500 text-center">No results found</p>
          )}
        </div>
      </div>
    </div>
  );
}
