import { Navigate, useNavigate, useParams } from "react-router-dom";
import { format } from "date-fns";
import useReports from "../../modules/api/hooks/useReports";
import InputField from "../../modules/components/InputField";
import { ArrowLeftIcon, PencilIcon } from "@heroicons/react/24/solid";

export function ShowReportPage() {
  const { id } = useParams();
  const { reports } = useReports();
  const navigate = useNavigate();
  const report = reports?.find((report) => report.journalNumber === id);

  if (!report) {
    return <Navigate to="/dashboard/reports" />;
  }

  return (
    <>
      <div className="py-12 px-4 max-w-7xl mx-auto">
        <div className="flex items-center justify-between sm:justify-start mb-6 space-x-1">
          <button
            className="btn-icon"
            onClick={() => navigate("/dashboard/reports/")}
          >
            <ArrowLeftIcon className=" h-6 w-6 font-bold text-gray-400" />
          </button>
          <p className="text-sm tracking-wide font-medium sm:text-2xl">
            Report - {report?.journalNumber}
          </p>
          <button
            className="btn-icon"
            onClick={() =>
              navigate("/dashboard/reports/edit/" + report?.journalNumber)
            }
          >
            <PencilIcon className="h-6 w-6 text-gray-400" />
          </button>
        </div>

        <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
          <InputField
            label="Journal number"
            name="journal-number"
            type="text"
            value={report?.journalNumber || ""}
            readonly
          />
          <InputField
            label="Frame number"
            name="frame-number"
            type="text"
            value={"SF" + report?.bikeReference || ""}
            readonly
          />
          <InputField
            label="Date"
            name="date"
            type="text"
            value={
              report?.date ? format(new Date(report.date), "dd/MM/yyyy") : ""
            }
            readonly
          />
          <InputField
            label="Active?"
            name="active"
            type="text"
            value={report?.active ? "Yes" : "No"}
            readonly
          />
          <div className="sm:col-span-2">
            <label
              htmlFor="journal-number"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Description
            </label>
            <textarea
              name="description"
              id="description"
              className="disabled:opacity-70 h-64 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5   dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Type the details of the police report here"
              required
              value={report?.description}
              readOnly
            />
            <div className="text-gray-400 text-xs mt-1">
              Last updated on{" "}
              {format(new Date(report?.updatedAt), "MMM dd, yyyy 'at' HH:mm")}
              {" by "}
              <p className="inline-flex text-xs">
                {report?.updatedBy || "Unknown"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
