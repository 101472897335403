import { Link, useNavigate } from "react-router-dom";
import { IUser } from "../../modules/api/interfaces/user";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import Table from "../../modules/components/table/Table";
import Button from "../../modules/components/Button";
import { useEffect } from "react";

export function UsersPage() {
  //const { users, isLoading } = useUsers();
  const navigate = useNavigate();
  const { getPermission } = useKindeAuth();

  useEffect(() => {
    if (!(getPermission && getPermission("VIEW_USERS")?.isGranted)) {
      navigate("/dashboard/statistics");
    }
  }, [getPermission, navigate]);

  const users: IUser[] = [];

  const columns = [
    {
      label: "ID",
      accessor: (row: IUser) => row.userId,
    },
    {
      label: "Name",
      accessor: (row: IUser) => row.name,
      render: (active: boolean) => (
        <span
          className={`px-2 py-1 rounded-full text-white ${
            active ? "bg-green-500" : "bg-red-500"
          }`}
        >
          {active ? "Yes" : "No"}
        </span>
      ),
    },
    {
      label: "Email",
      accessor: (row: IUser) => row.email,
    },
  ];

  return (
    <div className="py-12 px-4 max-w-7xl mx-auto">
      <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between">
        <div>
          <h1>Users</h1>
        </div>
        <div className="mt-4 sm:mt-0 w-full sm:w-auto sm:flex-shrink-0">
          <Link to="./new">
            <Button
              title="Create user"
              variant="primary"
              className="w-full sm:w-auto"
              disabled={true}
            />
          </Link>
        </div>
      </div>
      <div className="flex flex-row mt-4 gap-4">
        <input
          id="search"
          type="search"
          placeholder="Search for users..."
          className="disabled:opacity-70 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
        />
        <div className="w-full sm:w-auto">
          <Button
            title="Search"
            variant="secondary"
            className="w-full sm:w-auto"
            disabled={true}
          />
        </div>
      </div>
      <Table
        data={users}
        columns={columns}
        tableActions={{
          deleteFn: (item) => console.log("Deleting", item),
          deleteMessageFn: (item) =>
            `Are you sure you want to delete ${item.userId}?`,
        }}
        idAccessor={(row) => row.userId}
        isLoading={true}
        emptyMessage="No users found"
      />
      <p className="text-gray-600 text-xs italic">Not implemented yet!</p>
    </div>
  );
}
