import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { Navigate } from "react-router-dom";

export function WelcomePage() {
  const { login, isAuthenticated, isLoading } = useKindeAuth();

  if (isAuthenticated && !isLoading) {
    return <Navigate to="/dashboard/statistics" />;
  }

  return (
    <div className="h-screen flex items-center justify-center bg-white">
      <div className="max-w-sm w-full bg-white border border-gray-100 px-24 py-8 rounded-lg shadow-md text-center m-4">
        <img
          src="/swapfiets-logo.png"
          alt="swapfiets"
          className="mx-auto mb-2 w-16 h-16 rounded-lg"
        />
        <h1 className="text-xl font-semibold text-gray-800">SwapClaim</h1>
        {isLoading ? (
          <p className="text-gray-500 p-2.5">Loading...</p>
        ) : (
          <>
            <p className="text-gray-500 mb-3">Login to continue</p>
            <button
              onClick={() => login()}
              type="button"
              className="w-full btn"
            >
              Login
            </button>
          </>
        )}
        <a
          href="https://swapclaim.kinde.com/knock-knock"
          rel="noopener noreferrer"
          className="w-full btn-icon mt-3"
        >
          Request Access
        </a>
      </div>
    </div>
  );
}

export default WelcomePage;
