import { useState } from "react";
import { useCreateReport } from "../../modules/api/hooks/useCreateReport";
import useBikes from "../../modules/api/hooks/useBikes";
import BikeFrameNumberInput from "../../modules/components/BikeFrameNumberInput";
import Button from "../../modules/components/Button";
import toast from "react-hot-toast";

export interface IReportForm {
  journalNumber: string;
  date: string;
  description: string;
  active: string;
  bikeReference: string;
}

const descriptionPlaceholder = `How did it happen?:\n\nAddress:\n\nAdditional information:\n\nCreated by:\n`;

export function NewReportPage() {
  const createReportMutation = useCreateReport({
    onError: (errorMessage) => {
      setError(errorMessage);
      setIsCreating(false);
    },
  });
  const { bikes } = useBikes();
  const [form, setForm] = useState<IReportForm>({
    journalNumber: "",
    date: "",
    description: descriptionPlaceholder,
    active: "",
    bikeReference: "",
  });
  const [isCreating, setIsCreating] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = () => {
    let errorMessage = "";

    if (!form.journalNumber) {
      errorMessage += "• Journal number is required.\n";
    }
    if (
      !form.bikeReference ||
      !bikes?.find((bike) => bike.frameNumber === form.bikeReference)
    ) {
      errorMessage +=
        "• Bikes frame number is required or does not match an existing frame number.\n";
    }
    if (!form.date) {
      errorMessage += "• Date is required.\n";
    }
    if (!form.active || form.active === "default") {
      errorMessage += "• Active? is required.\n";
    }
    if (!form.description) {
      errorMessage += "• Description is required.\n";
    }

    if (errorMessage) {
      setError(errorMessage.trim());
      return;
    }

    setIsCreating(true);
    toast.promise(createReportMutation.mutateAsync(form), {
      loading: "Creating report...",
      success: "Report created successfully!",
      error: "Error creating report.",
    });
  };

  return (
    <>
      <div className="py-12 px-4 max-w-7xl mx-auto">
        <div className="flex items-center justify-between mb-6">
          <div>
            <h1>Create New Report</h1>
            <p className="text-gray-400 text-sm">
              Create a new report of a missing bike here on this page.
            </p>
          </div>
        </div>
        <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
          <div className="w-full">
            <label
              htmlFor="journal-number"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Journal number
            </label>
            <input
              type="text"
              name="journal-number"
              id="journal-number"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
              placeholder="Type the police journal number"
              required
              value={form?.journalNumber}
              onChange={(e) =>
                setForm({ ...form, journalNumber: e.target.value })
              }
            />
          </div>
          <BikeFrameNumberInput
            bikes={bikes || []}
            onSelectFrameNumber={(frameNumber: string) =>
              setForm({ ...form, bikeReference: frameNumber })
            }
          />
          <div className="w-full">
            <label
              htmlFor="bike-type"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Date
            </label>
            <input
              type="date"
              name="date"
              id="date"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
              placeholder="Type frame number of the bike"
              required
              value={form?.date}
              onChange={(e) => setForm({ ...form, date: e.target.value })}
            />
          </div>
          <div className="w-full">
            <label
              htmlFor="bike-reference"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Active?
            </label>
            <select
              id="bike-reference"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
              required
              value={form?.active}
              onChange={(e) => setForm({ ...form, active: e.target.value })}
            >
              <option value="default">Select an option</option>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="journal-number"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Description
            </label>
            <textarea
              name="description"
              id="description"
              className="bg-gray-50 h-64 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
              placeholder="Type the details of the police report here"
              required
              value={form?.description}
              onChange={(e) =>
                setForm({ ...form, description: e.target.value })
              }
            />
          </div>
        </div>
        <Button
          title="Create report"
          variant="primary"
          onClick={handleSubmit}
          isLoading={isCreating}
          disabled={isCreating}
          className="mt-4"
        />
        {error && (
          <div className="text-red-500 mt-4 whitespace-pre-wrap">{error}</div>
        )}
      </div>
    </>
  );
}
