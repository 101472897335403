import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { IReportForm } from "../../../pages/dashboard/reports.new";
import { API_URL } from "../../../constants";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";

interface IUseDeleteReportProps {
  onSuccess?: () => void;
  onError?: (error: any) => void;
}

// React Query hook to fetch bikes with access token
export const useCreateReport = (props?: IUseDeleteReportProps) => {
  const { getToken, getUser } = useKindeAuth();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const createNewReport = async (
    journalNumber: string,
    date: Date,
    description: string,
    active: boolean,
    bikeReference: string
  ) => {
    const accessToken = await getToken?.();
    const user = getUser?.();
    return await axios.post(
      API_URL + "/reports",
      {
        journalNumber: journalNumber,
        date: date,
        description: description,
        active: active,
        bikeReference: bikeReference,
        updatedBy: user ? `${user.given_name} ${user.family_name}` : "Unknown",
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  };

  return useMutation({
    mutationKey: ["post", "report"],
    mutationFn: (report: IReportForm) =>
      createNewReport(
        report.journalNumber,
        new Date(report.date),
        report.description,
        report.active === "true",
        report.bikeReference
      ),
    onError: (error: any) => {
      console.error(error);
      if (props?.onError) {
        props.onError(error.response?.data || "An error occurred");
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["reports"] });
      if (props?.onSuccess) {
        props.onSuccess();
      }
      navigate("/dashboard/reports");
    },
  });
};

export default useCreateReport;
