import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IBike } from "../interfaces/bike";
import { API_URL } from "../../../constants";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";

// React Query hook to fetch bikes with access token
export const useBikes = () => {
  const { getToken } = useKindeAuth();

  const fetchBikes = async (): Promise<IBike[]> => {
    try {
      const accessToken = await getToken?.();
      const { data } = await axios.get<IBike[]>(API_URL + "/bikes", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return data;
    } catch (error: any) {
      console.error("Failed to fetch bikes:", error);
      throw new Error(error.message);
    }
  };

  const { isLoading, data, error } = useQuery({
    queryKey: ["bikes"],
    queryFn: fetchBikes,
    select: (data) => data || [],
  });

  return { isLoading, bikes: data, error };
};

export default useBikes;
