import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "../../../constants";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { IUpdateReportForm } from "../../../pages/dashboard/reports.edit.$id";

interface IUseUpdateReportProps {
  onSuccess?: () => void;
  onError?: () => void;
}

export const useUpdateReport = (props?: IUseUpdateReportProps) => {
  const { getToken, getUser } = useKindeAuth();
  const queryClient = useQueryClient();

  const updateReportWithJournalNumber = async (report: IUpdateReportForm) => {
    const accessToken = await getToken?.();
    const user = getUser?.();
    const isActive = report.active === "true";

    return await axios.put(
      API_URL + "/reports",
      {
        report: {
          journalNumber: report.journalNumber,
          date: report.date,
          description: report.description,
          active: isActive,
          bikeReference: report.bikeReference,
          updatedBy: user
            ? `${user.given_name} ${user.family_name}`
            : "Unknown",
        },
        newJournalNumber: report.newJournalNumber,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  };

  return useMutation({
    mutationKey: ["update", "report"],
    mutationFn: (report: IUpdateReportForm) =>
      updateReportWithJournalNumber(report),
    onError: (error: Error) => {
      console.error(error);
      props?.onError?.();
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["reports"] });
      await queryClient.invalidateQueries({ queryKey: ["bikes"] });
      props?.onSuccess?.();
    },
  });
};
