import { useState, useEffect, useRef, useMemo } from "react";
import { IBike } from "../api/interfaces/bike";

interface BikeFrameNumberInputProps {
  bikes: IBike[];
  onSelectFrameNumber: (frameNumber: string) => void; // Callback function to pass data to parent
  dataInput?: string;
}

export default function BikeFrameNumberInput({
  bikes,
  dataInput,
  onSelectFrameNumber,
}: BikeFrameNumberInputProps) {
  const [form, setForm] = useState({ bikeReference: dataInput || "" }); // Track the input value
  const [isListOpen, setIsListOpen] = useState(false); // Track the visibility of the list
  const [isValid, setIsValid] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null); // Reference to the component wrapper

  const validateInput = (value: string) => {
    const isValidFrameNumber =
      value.length > 0 &&
      bikes.some(
        (bike: IBike) => bike.frameNumber.toLowerCase() === value.toLowerCase()
      );
    setIsValid(isValidFrameNumber);
    onSelectFrameNumber(value);
  };

  useEffect(() => {
    validateInput(dataInput ?? "");
  }, [dataInput]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleInputChange = (e: { target: { value: any } }) => {
    const value = e.target.value;
    setForm({ ...form, bikeReference: value });
    setIsListOpen(true); // Show the list when typing
    validateInput(value);
  };

  const handleItemClick = (frameNumber: string) => {
    setForm({ ...form, bikeReference: frameNumber });
    setIsListOpen(false); // Close the list after selecting an item
    validateInput(frameNumber); // Validate and trigger callback if valid
  };

  // Memoize the filtered bikes based on the current input value
  const filteredBikes = useMemo(() => {
    if (form.bikeReference.length === 0) return [];
    return bikes.filter((bike: IBike) =>
      bike.frameNumber.toLowerCase().includes(form.bikeReference.toLowerCase())
    );
  }, [form.bikeReference, bikes]);

  // Handle click outside the component
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setIsListOpen(false); // Just close the list without clearing the input
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <div className="relative w-full" ref={wrapperRef}>
      <label
        htmlFor="bike-reference"
        className="block mb-2 text-sm font-medium text-gray-900"
      >
        Frame number
      </label>
      <input
        type="text"
        name="bike-reference"
        id="bike-reference"
        className={`bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-customBlue focus:border-customBlue block w-full p-2.5 ${
          isValid ? "border-green-500" : "border-red-500"
        }`}
        placeholder="Type frame number of the bike"
        required
        value={form.bikeReference}
        onChange={handleInputChange}
      />

      {/* Suggestions List */}
      {isListOpen && filteredBikes.length > 0 && (
        <ul className="absolute bg-white border border-gray-300 mt-1 rounded-lg shadow-md max-h-60 overflow-y-auto w-full z-10">
          {filteredBikes.slice(0, 50).map((bike, index) => (
            <li
              key={index}
              className="p-2 hover:bg-customBlue hover:text-white cursor-pointer"
              onClick={() => handleItemClick(bike.frameNumber)}
            >
              {bike.frameNumber}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
