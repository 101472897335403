import { Navigate, useNavigate, useParams } from "react-router-dom";
import useReports from "../../modules/api/hooks/useReports";
import { useState } from "react";
import useBikes from "../../modules/api/hooks/useBikes";
import BikeFrameNumberInput from "../../modules/components/BikeFrameNumberInput";
import { useUpdateReport } from "../../modules/api/hooks/useUpdateReport";
import { format } from "date-fns";
import Button from "../../modules/components/Button";
import InputField from "../../modules/components/InputField";
import { ArrowLeftIcon, EyeIcon } from "@heroicons/react/24/solid";
import toast from "react-hot-toast";

export interface IUpdateReportForm {
  journalNumber: string;
  date: string;
  description: string;
  active: string;
  bikeReference: string;
  newJournalNumber: string;
}

export function EditReportPage() {
  const { mutate: updateReport } = useUpdateReport({
    onSuccess: () => {
      toast.dismiss();
      setIsUpdating(false);
      toast.success("Report updated successfully!");
      navigate("/dashboard/reports/" + form.newJournalNumber);
    },
    onError: () => {
      toast.dismiss();
      setIsUpdating(false);
      toast.error("An error occurred while updating the report.");
    },
  });
  const { id } = useParams();
  const { reports } = useReports();
  const { bikes } = useBikes();
  const navigate = useNavigate();
  const [isUpdating, setIsUpdating] = useState(false);
  const report = reports?.find((report) => report.journalNumber === id);

  if (!report) {
    <Navigate to="/dashboard/reports" />;
  }

  const [form, setForm] = useState<IUpdateReportForm>({
    journalNumber: report?.journalNumber || "",
    date: report?.date ? format(report.date, "yyyy-MM-dd") : "",
    description: report?.description || "",
    active: report?.active ? "true" : "false",
    bikeReference: report?.bikeReference || "",
    newJournalNumber: report?.journalNumber || "",
  });
  const [error, setError] = useState("");

  const handleSubmit = () => {
    let errorMessage = "";

    if (!form.newJournalNumber) {
      errorMessage += "Journal number is required.\n";
    }
    if (
      !form.bikeReference ||
      !bikes?.find((bike) => bike.frameNumber === form.bikeReference)
    ) {
      errorMessage +=
        "Bikes frame number is required or does not match an existing frame number.\n";
    }
    if (!form.date) {
      errorMessage += "Date is required.\n";
    }
    if (!form.active || form.active === "default") {
      errorMessage += "Active? is required.\n";
    }
    if (!form.description) {
      errorMessage += "Description is required.\n";
    }

    if (errorMessage) {
      setError(errorMessage.trim());
      return;
    }
    setIsUpdating(true);
    toast.loading("Updating report...");
    updateReport(form);
  };

  return (
    <>
      <div className="py-12 px-4 max-w-7xl mx-auto">
        <div className="flex items-center justify-between sm:justify-start mb-6 space-x-1">
          <button
            className="btn-icon"
            onClick={() => navigate("/dashboard/reports/")}
          >
            <ArrowLeftIcon className=" h-6 w-6 font-bold text-gray-400" />
          </button>
          <p className="text-sm tracking-wide font-medium sm:text-2xl">
            Edit report - {report?.journalNumber}
          </p>
          <button
            className="btn-icon"
            onClick={() =>
              navigate("/dashboard/reports/" + report?.journalNumber)
            }
          >
            <EyeIcon className="h-6 w-6 text-gray-400" />
          </button>
        </div>

        <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
          <InputField
            label="Journal number"
            name="journal-number"
            type="text"
            value={form?.newJournalNumber}
            onChange={(e) =>
              setForm({ ...form, newJournalNumber: e.target.value })
            }
          />
          <BikeFrameNumberInput
            bikes={bikes || []}
            onSelectFrameNumber={(frameNumber: string) =>
              setForm({ ...form, bikeReference: frameNumber })
            }
            dataInput={form?.bikeReference}
          />
          <InputField
            label="Date"
            name="date"
            type="date"
            value={form?.date}
            onChange={(e) => setForm({ ...form, date: e.target.value })}
          />
          <div className="w-full">
            <label
              htmlFor="active"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Active?
            </label>
            <select
              id="active"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
              required
              value={form.active}
              onChange={(e) => setForm({ ...form, active: e.target.value })}
            >
              <option value="default">Select an option</option>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="description"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Description
            </label>
            <textarea
              name="description"
              id="description"
              className="disabled:opacity-70 h-64 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5   dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Type the details of the police report here"
              required
              value={form?.description}
              onChange={(e) =>
                setForm({ ...form, description: e.target.value })
              }
            />
          </div>
        </div>
        <Button
          title="Update report"
          variant="primary"
          onClick={handleSubmit}
          isLoading={isUpdating}
          disabled={isUpdating}
          className="mt-4"
        />
        {error && (
          <div className="text-red-500 mt-4 whitespace-pre-wrap">{error}</div>
        )}
      </div>
    </>
  );
}
