import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { BikeType } from "../interfaces/bike";
import { useNavigate } from "react-router-dom";
import { IBikeForm } from "../../../pages/dashboard/bikes.new";
import { API_URL } from "../../../constants";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";

interface IUseDeleteBikeProps {
  onSuccess?: () => void;
  onError?: (error: any) => void;
}

export const useCreateBike = (props?: IUseDeleteBikeProps) => {
  const { getToken } = useKindeAuth();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const createNewBike = async (
    frameNumber: string,
    bikeType: BikeType,
    color: string
  ) => {
    const accessToken = await getToken?.();
    return await axios.post(
      API_URL + "/bikes",
      {
        frameNumber: frameNumber,
        bikeType: bikeType,
        color: color,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  };

  return useMutation({
    mutationKey: ["post", "bike"],
    mutationFn: (bike: IBikeForm) =>
      createNewBike(bike.frameNumber, bike.bikeType, bike.color),
    onError: (error: any) => {
      console.error(error);
      if (props?.onError) {
        props.onError(error.response?.data || "An error occurred");
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["bikes"] });
      if (props?.onSuccess) {
        props.onSuccess();
      }
      navigate("/dashboard/bikes");
    },
  });
};
